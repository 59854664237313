/* exceptions  */
.Gallery_image24{
    margin-right: 0em;
  }
  .Gallery_image23{
    grid-column: 3;
    grid-row: 1 / span 2;
    display: flex;
    /* min-width: max-content; */
    max-height: 100vh;
    margin-bottom: 0;
  }
  .Gallery_image23 img{
    height: 95vh;
    object-fit: cover;
  }
  .Gallery_image21{
    grid-column: 2 ;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    margin-bottom: 0;
  }
  .Gallery_image21 img{
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  .Gallery_image22_text{
    grid-column: 2 ;
    grid-row: 2;
    display: flex;
    min-height: calc(100% - 60px);
    height: 50vh;
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
    min-width:max-content;
  }
/* Correction */
.Gallery_image30{
  grid-column: 2;
  grid-row: 1 / span 2;
  display: flex;
  /* min-width: max-content; */
  max-height: 100vh;
  margin-bottom: 0;
}
  .Gallery_image31{
    grid-column: 1;
    grid-row: 1 / span 2;
    display: flex;
    /* min-width: max-content; */
    max-height: 100vh;
    margin-bottom: 0;
  }
  .Gallery_image31 img{
    /* width: 100%; */
    height: 95vh;
    object-fit: cover;
  }
  .Gallery_image32{
    grid-column: 3 ;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    margin-bottom: 0;
  }
  .Gallery_image32 img{
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  .Gallery_image33_text{
    grid-column: 3 ;
    grid-row: 2;
    display: flex;
    min-height: calc(100% - 60px);
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color:#CCCCCC;
    min-width:max-content;
  }
/* Correction */
  .Gallery_image43{
    grid-column: 2/span 2;
    grid-row: 2;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    margin-bottom: 0;
  }

  .Gallery_image42{
    grid-column: 2 / span 2;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    margin-bottom: 0;
  }
  .Gallery_image42 img{
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  .Gallery_image41_text{
    width: 0%;
  }
  .Gallery_tile41{
  width: 0%;
  }
/* correction */
.Gallery_image52_text{
  grid-column: 2 / span 2;
    grid-row: 2;
    display: flex;
    min-height: calc(100% - 60px);
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #BBB5B5;
    min-width:max-content;
}
.Gallery_image53{
  max-width: 0;
}
.Gallery_image53 img{
  max-width: 0;
}
/* Correction */
.Gallery_image61{
  grid-column: 1/span 2;
  grid-row: 1 / span2;
  display: flex;
  /* min-width: max-content; */
  max-height: 100vh;
  margin-bottom: 0;
}
.Gallery_image61 img{
  /* width: 100%; */
  height: 95vh;
  object-fit: cover;
}
.Gallery_image61_text{
width: 0%;
}
.Gallery_tile61{
width: 0%;
}
.Gallery_image63_text{
  grid-column: 3;
  grid-row: 1 / span2;
  display: flex;
  min-width: max-content;
  max-height: 100vh;
  margin-bottom: 0;
}
.Gallery_image63_text img{
  width: 100%;
  height: 95vh;
  object-fit: cover;
}
/* Correction */
.Gallery_image73{
  width: 0%;
}
.Gallery_image73 img{
  width: 0%;
}

.Gallery_image72{
  grid-column: 2 ;
  grid-row: 1 / span 2;
  display: flex;
  /* min-width: max-content; */
  max-height: 100vh;
  margin-bottom: 0;
}
.Gallery_image72 img{
  /* width: 100%; */
  height: 95vh;
  object-fit: cover;
}
.Gallery_image71_text{
  grid-column: 3;
    grid-row: 1/ span 2;
    display: flex;
    min-height: calc(100%);
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 60px 0px 60px;
    background-color: #BBB5B5;
    min-width:max-content;
}
.Gallery_tile71{
  max-height: 100vh;;
  font-family: "Lustria",Sans-serif;
  font-size: 24px;
  font-weight:500;
  color: #0C142D;
  
  line-height: 1.4074;
  text-align: left;
}
.Gallery_image11_text{
  background-color:  #9BC5C3;
  padding: 40px 60px 0px 60px;
}

/* Indian Road Custom image specs */
.Gallery_image80{
    grid-column: 1 ;
    grid-row: 1 / span 2;
    display: flex;
    /* min-width: max-content; */
    max-height: 100vh;
    align-self: flex-start;
    margin-bottom: 0;
  }
  .Gallery_image80 img{
    /* width: 100%; */
    height: 95vh;
    object-fit: cover;
  }
.Gallery_image81,.Gallery_image91{
    grid-column: 2 ;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    width: fit-content;
    align-self: flex-start;
    margin-bottom: 0;
  }
  .Gallery_image81 img{
    /* width: 100%; */
    height: 40vh;
    object-fit: cover;
  }
  .Gallery_image91 img{
    /* width: 100%; */
    height: 40vh;
    object-fit: cover;
  }
  
.Gallery_image82_text,.Gallery_image93_text{
    grid-column: 2 ;
      grid-row: 2;
      display: flex;
      min-height: calc(100%);
      flex-wrap: wrap;
      text-align: left;
      align-items: top;
      padding: 40px 40px 0px 40px;
      background-color: #BBB5B5;
      height: 50vh;
      
      
      /* min-width:max-content; */
}.Gallery_tile82,.Gallery_tile101,.Gallery_tile112,.Gallery_tile93{
    max-height: 55vh;
    font-family: "Cairo","Poppins", sans-serif;
    font-size: 2vh;
    font-weight: 500;
    color: #0C142D;
    
    line-height: 1.4074;
    text-align: left;
}

.Gallery_image83{
    grid-column: 3 ;
    grid-row: 1/ span 2;
    display: flex;
    /* min-width: max-content; */
    max-height: 100vh;
    align-self: flex-start;
    margin-bottom: 0;
  }
  .Gallery_image83 img{
    /* width: 100%; */
    height: 95vh;
    object-fit: cover;
  }

.Gallery_image93_text{
    grid-column: 2;
    grid-row: 2;
    display: flex;
    /* min-height: calc(100% - 60px); */
    height: 50vh;
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
    
}  
.Gallery_video90, .Gallery_video140{
    grid-column: 1;
    grid-row: 1 /span 2;
    /* margin-right: 1em; */
    /* min-width: min-content; */
    /* min-height: calc(100% - 70px); */
    height: 95vh;
    max-width: 35vw;
    /* width: 120vw; */
  }
.Gallery_video113{
    grid-column: 3;
    grid-row: 1 /span 2;
    /* margin-right: 1em; */
    /* min-width: min-content; */
    /* min-height: calc(100% - 70px); */
    height: 95vh;
    max-width: 35vw;
    /* width: 120vw; */
  }
  .video-container1 {
    position: relative;
    display: flex;
    /* width:100%; */
    /* padding-bottom: 42.25%;
    padding-top: 66.25%;
    /* padding-bottom: 50%; */
    /*height: 0; */
    overflow: hidden;
  }
  .video-container1 iframe {
    position: absolute;
    top: -5%;
    /* bottom: 10%; */
    left: 0;
    /* width: 100%; */
    height: 70%;
    pointer-events: none;
    object-fit: fill;
  }
.Gallery_image94{
    grid-column: 3 /span 2;
    grid-row: 1 /span 2;
    min-width:max-content;
    /* margin-right: 1em; */
    /* max-width: 100vw; */
    /* min-height: calc(100% - 70px); */
    max-height: 100vh;
    margin-right:1em ;
  }

.Gallery_image101_text,.Gallery_image112_text{
    grid-column: 2 ;
    grid-row: 2;
    display: flex;
    max-width: 50vw;
    /* min-height: calc(100% - 60px); */
    height: 50vh;
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
}
.Gallery_image102,.Gallery_image111{
    grid-column: 2;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    margin-bottom: 0;
}
.Gallery_image111 img{
    
  height: 100%;
  object-fit: cover;
}
.Gallery_image103{
    grid-column: 3;
    grid-row: 1/span 2;
    display: flex;
    /* min-width: max-content; */
    height: 95vh;
    margin-bottom: 0;
}
.Gallery_image104{
    grid-column: 4;
    grid-row: 1/span 2;
    display: flex;
    /* min-width: max-content; */
    height: 95vh;
    margin-bottom: 0;
}
.Gallery_image102 img, .Gallery_image104 img, .Gallery_image103 img{
    height: 100%;
    object-fit: cover;
}
.Gallery_image121 img, .Gallery_image122 img,.Gallery_image133 img,
.Gallery_image132 img,.Gallery_image141 img,.Gallery_image143 img,
.Gallery_image150 img,.Gallery_image151 img{
    height: 95vh;
    object-fit: cover;
}
.Gallery_image121,.Gallery_image132,.Gallery_image141{
    grid-column: 2;
    grid-row: 1 /span 2;
    display: flex;
    /* min-width: max-content; */
    /* min-width: 100%; */
    height: 95vh;
    margin-bottom: 0;
}
.Gallery_image122,.Gallery_image133,.Gallery_image143{
    grid-column: 3;
    grid-row: 1 /span 2;
    display: flex;
    /* min-width: max-content; */
    /* min-width: 100%; */
    max-height: 95vh;
    margin-bottom: 0;
}
.Gallery_image150,.Gallery_image151{
    grid-column: 1;
    grid-row: 1 /span 2;
    display: flex;
    /* min-width: max-content; */
    /* min-width: 100%; */
    max-height: 95vh;
    margin-bottom: 0;
}
.Gallery_image123_text,.Gallery_image131_text{
    grid-column: 4 ;
    grid-row: 2;
    
  }
/* Correction */

 /*.Gallery_video44, .Gallery_video64{
  /* display: flex; 
  /* min-width: max-content; 
  grid-column: 4;
  grid-row: 1 /span 2;
  margin-right: 1em;
  /* min-width: min-content; 
  /* min-height: calc(100% - 70px); 
  max-height: 100vh;
  

  
}*/
.Gallery_video14,.Gallery_video44, .Gallery_video64{
  
  grid-column: 4;
  grid-row: 1 /span 2;
  margin-right: 1em;
  /* min-width: min-content; */
  /* min-height: calc(100% - 70px); */
  height: 90vh;
  /* width: 120vw; */
}
.video-container {
  position: relative;
  /* padding-bottom: 42.25%; */
  padding-top: 66.25%;
  /* padding-bottom: 50%; */
  height: 0;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top: -10%;
  /* bottom: 10%; */
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: fill;
}


  /*end of corrections  */
  
  
  
  
  

  .Gallery_images3 {
    grid-area: ThirdImage;
    display: flex;
    min-width: max-content;
    max-height: 100%;
    
  }
  .Gallery_images3 img{
  
    width: 100%;
  height: 100%;
  object-fit: cover;
    
    
  }
  .Gallery_image3_text{
    grid-area: ThirdImage;
    display: flex;
    min-height: calc(100% - 60px);
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
    min-width:max-content;
  }
  .Gallery_tile2{
    
    max-height: 100%;;
    font-family: "Lustria", Sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-transform:none;

    color: #0C142D;
    
    line-height: 1.4074;
    text-align: left;
  }
  .Gallery_images4{
    grid-area: FourthImage;
    display: flex;
    flex-wrap: wrap;
    min-width:max-content;
    max-height: 100%;
    
  }
  .Gallery_images4 img{
  
    width: 100%;
  height: 100%;
  object-fit: cover;
    
    
  }
  .Gallery_image4_text{
    grid-area: FourthImage;
    display: flex;
    min-height: calc(100% - 60px);
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
    min-width:max-content;
  }
  .Gallery_tile3{
    
    max-height: 100%;;
    font-family: "Lustria", Sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #0C142D;
    
    line-height: 1.4074;
    text-align: left;
  }
  

 
 
  .Gallery_ArrowRight{
    position: fixed;
    top: 50%;
    right: -30px;
    width: 50px;
    transition: 0.3s;
    padding: 10px;
    text-decoration: none;
    font-size: 16px;
    color: #0622e6;
    z-index: 9999;
    background-color: rgb(214 232 247 / 0%);
    border-radius: 25px 0px 0px 25px;
    cursor: pointer;
    opacity: 1;
    
  }
  .Gallery_ArrowRight img{
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    
  }
  .Gallery_ArrowLeft{
    position: fixed;
    top: 50%;
    left: -30px;
    width: 50px;
    transition: 0.3s;
    padding: 10px;
    text-decoration: none;
    font-size: 16px;
    color: #0622e6;
    z-index: 9999;
    background-color: rgb(214 232 247 / 0%);
    border-radius: 0px 25px 25px 0px;
    cursor: pointer;
    opacity: 1;
    
  }
  
  
  .Gallery_ArrowLeft img{
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    
  }