.About_main{
    display: grid;
    height: calc(100vh - 70px);
    /* max-width: 100vw; */
    /* min-width:100%; */
    /* grid-template-columns: repeat(4,1fr); */
    grid-template-columns:55vw 45vw;
    grid-template-rows: 100%;
    grid-gap: 1em;
    margin-right: 1em;
    overflow-y: auto;
    overflow-x: hidden;
    text-transform: none;

}

.About_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    

}
.About_header{
    color: #C49A6C;
    font-family: "Cairo","Lustria","Poppins";
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 10%;
    margin-right: 10%;
}
.About_header h2{
    font-weight: 500;
}
.About_body{
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    line-height: 2;
    text-align: left;
    color: #C49A6C;
    font-family: "Poppins",sans-serif;
    flex-wrap: wrap;
    overflow: auto;
}

.About_image2{
    grid-column: 2;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
}
.About_image2 img{
    width: 100%;
  height: 100%;
  object-fit: cover;
}

@media(max-width:1200px){
    .About_main{
        display: grid;
        height: calc(100vh - 70px);
        /* max-width: 100vw; */
        min-width:100%;
        /* grid-template-columns: repeat(4,1fr); */
        grid-template-columns:auto;
        grid-template-rows: 100%;
        grid-gap: 1em;
        margin-right: 1em;
        overflow-y: auto;
        overflow-x: hidden;
        text-transform: none;
    
    }
    
    .About_info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    
    }
    .About_header{
        color: #C49A6C;
        font-family: "Cairo","Lustria","Poppins";
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 10%;
        margin-right: 10%;
    }
    
    .About_body{
        display: flex;
        margin-left: 10%;
        margin-right: 10%;
        line-height: 2;
        text-align: left;
        color: #C49A6C;
        font-family: "Poppins",sans-serif;
        flex-wrap: wrap;
        overflow: auto;
    }
    
    .About_image2{
        grid-column: 2;
        grid-row: 1;
        display: flex;
        min-width: max-content;
    }
    .About_image2 img{
        width: 100%;
      height: 100%;
      object-fit: cover;
    }
}

