
  @media(max-width:1200px){
    .Gallary_main{
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      text-transform: none;
      
    }
    
  
    
    
    .Gallery_image00, .Gallery_image10, .Gallery_image20,
    .Gallery_image40,.Gallery_image50, .Gallery_image70,.Gallery_image80,
    .Gallery_image90, .Gallery_image100, .Gallery_image110, 
    .Gallery_image120,.Gallery_image130, .Gallery_image140{
      /* grid-column: 1;
      grid-row: 1; */
      /* max-width: 50vw; */
      display: flex;
      min-width: 100%;
      max-height: 100vh;
      min-height: auto;
      margin-bottom: 10px;
    }
    .Gallery_image00 img, .Gallery_image10 img, .Gallery_image20 img,
    .Gallery_image30 img,.Gallery_image40 img,.Gallery_image50 img,
    .Gallery_image70 img,.Gallery_image80 img,.Gallery_image90 img,
    .Gallery_image100 img, .Gallery_image110 img, 
    .Gallery_image120 img,.Gallery_image130 img, .Gallery_image140 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    }
  
    .Gallery_image01, .Gallery_image11,.Gallery_image31
    ,.Gallery_image41,.Gallery_image51,  .Gallery_image71,
    .Gallery_image101, .Gallery_image111, 
    .Gallery_image121,.Gallery_image131, .Gallery_image141 {
      /* grid-column: 1;
      grid-row: 2; */
      display: flex;
      min-width: min-content;
      min-height: auto;
      margin-bottom: 0;
      margin-bottom: 10px;
    }
    .Gallery_image01 img, .Gallery_image11 img,
    .Gallery_image31 img,.Gallery_image41 img,.Gallery_image51 img,
    .Gallery_image61 img, .Gallery_image71 img,.Gallery_image81 img,
    .Gallery_image91 img,.Gallery_image101 img, .Gallery_image111 img, 
    .Gallery_image121 img,.Gallery_image131 img, .Gallery_image141 img{
      width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    }
  
    .Gallery_image02, .Gallery_image12, .Gallery_image22,.Gallery_image32
    ,.Gallery_image42,.Gallery_image52, .Gallery_image72,.Gallery_image82,
    .Gallery_image102, .Gallery_image112, 
    .Gallery_image122,.Gallery_image132, .Gallery_image142  {
      /* grid-column: 1;
      grid-row: 3; */
      display: flex;
      min-width: min-content;
      min-height: auto;
      margin-bottom: 0;
      margin-bottom: 10px;
    }
    .Gallery_image02 img, .Gallery_image12 img, .Gallery_image22 img,
    .Gallery_image32 img,.Gallery_image42 img,.Gallery_image52 img,
     .Gallery_image72 img,.Gallery_image82 img,
     .Gallery_image102 img, .Gallery_image112 img, 
     .Gallery_image122 img,.Gallery_image132 img, .Gallery_image142 img{
      width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    }
  
    .Gallery_image03, .Gallery_image13
    ,.Gallery_image43, .Gallery_image63 {
      /* grid-column: 1;
      grid-row: 4; */
      display: flex;
      min-width: min-content;
      /* max-height: 50vh; */
      min-height: auto;
      margin-bottom: 0;
      margin-bottom: 10px;
    }
    .Gallery_image03 img, .Gallery_image13 img,
    .Gallery_image33 img,.Gallery_image43 img,
     .Gallery_image63 img{
      width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    }
  
    .Gallery_image03_text,.Gallery_image13_text, .Gallery_image23_text,
    .Gallery_image33_text, .Gallery_image43_text, .Gallery_image53_text,
    .Gallery_image73_text,.Gallery_image93_text{
      /* grid-column: 1;
      grid-row: 4; */
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      align-items: top;
      padding: 0px 0px 0px 2px;
      background-color: #D8C9BE;
      min-width:100vw;
      min-height: 150px;
      min-height: auto;
      margin-bottom: 10px;
     
    }
    .Gallery_tile03, .Gallery_tile13, .Gallery_tile23, .Gallery_tile33,
    .Gallery_tile43, .Gallery_tile53,  .Gallery_tile73, .Gallery_tile93{
      display: flex;  
      flex-wrap: wrap;
      max-width: 100vw;
      max-height: 100%;;
      font-family: "Cairo","Poppins", sans-serif;
      font-size: 24px;
      font-weight: 500;
      color: #0C142D;
      padding-left: 15px;
      line-height: 1.4074;
      text-align: left;
    }
  
    .Gallery_image02_text,.Gallery_image12_text, 
    .Gallery_image32_text, .Gallery_image42_text, .Gallery_image52_text,
    .Gallery_image62_text, .Gallery_image72_text, .Gallery_image82_text,
    .Gallery_image112_text{
      grid-column: 1;
      grid-row: 2;
      display: flex;
      min-height: calc(100% - 60px);
      flex-wrap: wrap;
      text-align: left;
      align-items: top;
      padding: 0px 0px 0px 2px;
      background-color: #D8C9BE;
      min-width:100vw;
      min-height: auto;
      margin-bottom: 10px;
    }
    .Gallery_tile02, .Gallery_tile12, .Gallery_tile22, .Gallery_tile32,
     .Gallery_tile52, .Gallery_tile62, .Gallery_tile72, .Gallery_tile82,
     .Gallery_tile112{
      
      max-height: 100%;;
      font-family: "Cairo","Poppins", sans-serif;
      font-size: 24px;
      font-weight: 500;
      color: #0C142D;
      padding-left: 15px;
      line-height: 1.4074;
      text-align: left;
    }
  
    .Gallery_image01_text,.Gallery_image11_text, .Gallery_image21_text,
    .Gallery_image31_text, .Gallery_image51_text,
    .Gallery_image61_text, .Gallery_image101_text{
      grid-column: 1;
      grid-row: 1;
      display: flex;
      min-height: calc(100% - 60px);
      flex-wrap: wrap;
      text-align: left;
      align-items: top;
      padding: 0px 0px 0px 2px;
      background-color: #D8C9BE;
      min-width:100vw;
      min-height: auto;
      margin-bottom: 10px;
    }
    .Gallery_tile01, .Gallery_tile11, .Gallery_tile21, .Gallery_tile31,
    .Gallery_tile41, .Gallery_tile51, .Gallery_tile61,.Gallery_tile101{
      
      max-height: 100%;;
      font-family: "Cairo","Poppins", sans-serif;
      font-size: 24px;
      font-weight: 500;
      color: #0C142D;
      padding-left: 15px;
      line-height: 1.4074;
      text-align: left;
    }
    .Gallery_image04, .Gallery_image14, .Gallery_image24,.Gallery_image34
    ,.Gallery_image44,.Gallery_image54, .Gallery_image64, .Gallery_image74,
    .Gallery_image84,.Gallery_image94, .Gallery_image104, .Gallery_image114, 
    .Gallery_image124,.Gallery_image134, .Gallery_image144{
    
      /* min-width: 100vw; */
      display: flex;
      min-width:100vw;
      margin-right: 1em;
      
      min-height: auto;
      margin-bottom: 10px;
    }
    .Gallery_image04 img, .Gallery_image14 img, .Gallery_image24 img,
    .Gallery_image34 img, .Gallery_image44 img,.Gallery_image54 img,
    .Gallery_image64 img, .Gallery_image74 img, .Gallery_image84 img,
    .Gallery_image94 img, .Gallery_image104 img, .Gallery_image114 img, 
    .Gallery_image124 img,.Gallery_image134 img, .Gallery_image144 img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  


    
  
   
   
  
  
  }





