.Connect_main{
    display: grid;
    height: calc(100vh - 70px);
    /* max-width: 100vw; */
    min-width:100%;
    /* grid-template-columns: repeat(4,1fr); */
    grid-template-columns:55vw 45vw;
    grid-template-rows: 100%;
    grid-gap: 1em;
    margin-right: 1em;
    overflow-y: auto;
    overflow-x: hidden;

}

.Connect_info{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    max-height: 100%;
    /* margin-top: 10%; */
    margin-left: 10%;
    margin-right: 10%;
    line-height: 1.5;
    text-align: left;
    color: #C49A6C;
    font-family: "Poppins",sans-serif;
    
    /* overflow: auto; */
    text-transform: none;
    font-size: 16px;
    

}
.Connect_header{
    color: #C49A6C;
    font-family: "Cairo","Lustria","Poppins";
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    
}

.Connect_body1{
   font-weight: bold;
   margin-bottom: 20px;
}
.Connect_body2{
    font-weight: 100;
 }
 .Connect_body3{
    cursor: pointer;
    color:  rgb(99, 139, 218);
    white-space: pre;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
 }
 .Connect_body4{
    font-weight: 100;
 }
 .Connect_body5{
    font-weight: bold;
    margin-bottom: 30px;
 }
 .Connect_body6{
    font-weight: 100;
    margin-bottom: 20px;
 }
 .Connect_body7{
    font-weight: bold;
 }
 .Connect_body8{
    font-weight: bold;
 }

 .Connect_body7_message_row1{
     display: flex;
 }
 .Connect_body7_message_row1_name{
     display: flex;
     flex-direction: column;
     margin-right: 10px;
     flex-grow: 1;
     margin-bottom: 10px;
     overflow: hidden;
 }
 .Connect_body7_message_row1_name input{
    background-color: #1b2442;
    color: #ffffff;
    min-height: 30px;
    border: none;
 }
 .Connect_body7_message_row1_email{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 10px;
    border: none;
}
.Connect_body7_message_row1_email input{
   background-color: #1b2442;
   color: #ffffff;
   min-height: 30px;
   border: none;
}
.Connect_body7_message_row2_subject{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 10px;
    border: none;
}
.Connect_body7_message_row2_subject input{
   background-color: #1b2442;
   color: #ffffff;
   min-height: 30px;
   border: none;
}
.Connect_body7_message_row3_message{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 10px;
    border: none;
}
.Connect_body7_message_row3_message textarea{
   background-color: #1b2442;
   color: #ffffff;
   min-height: 140px;
   border: none;
}

.Connect_body7_message_row4_send{
    
    display: flex;
}
.Connect_body7_message_row4_send input{
    font-size: 17px;
    font-family: "Cairo","Lustria","Poppins";
    font-weight: 500;
    background-color: #c49a6c;
    color: #1b2442;
    padding: 10px 10px;
    border: none;

}




.Connect_image2{
    grid-column: 2;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
}
.Connect_image2 img{
    width: 100%;
  height: 100%;
  object-fit: cover;
}

@media(max-width:768px){
    .Connect_main{
        display: grid;
        height: calc(100vh - 70px);
        /* max-width: 100vw; */
        min-width:100%;
        /* grid-template-columns: repeat(4,1fr); */
        grid-template-columns:100vw;
        grid-template-rows: 100%;
        grid-gap: 1em;
        margin-right: 1em;
        overflow-y: hidden;
        overflow-x: hidden;
    
    }
    
    .Connect_info{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        margin-left: 10%;
        margin-right: 10%;
        line-height: 1.5;
        text-align: left;
        color: #C49A6C;
        font-family: "Poppins",sans-serif;
        
        overflow: auto;
        text-transform: none;
        font-size: 16px;
        
    
    }
    .Connect_header{
        color: #C49A6C;
        font-family: "Cairo","Lustria","Poppins";
        font-size: 30px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        
    }
    
    .Connect_body1{
       font-weight: bold;
       margin-bottom: 20px;
       text-align: center;
    }
    .Connect_body2{
        font-weight: 100;
        text-align: center;
     }
     .Connect_body3{
        cursor: pointer;
        color:  rgb(99, 139, 218);
        white-space: pre;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 20px;
        text-align: center;
     }
     .Connect_body4{
        font-weight: 100;
        text-align: center;
     }
     .Connect_body5{
        font-weight: bold;
        margin-bottom: 30px;
        text-align: center;
     }
     .Connect_body6{
        font-weight: 100;
        margin-bottom: 20px;
        text-align: center;
     }
     .Connect_body7{
        font-weight: bold;
     }
     .Connect_body8{
        font-weight: bold;
     }
    
     .Connect_body7_message_row1{
         display: flex;
     }
     .Connect_body7_message_row1_name{
         display: flex;
         flex-direction: column;
         margin-right: 10px;
         flex-grow: 1;
         margin-bottom: 10px;
         overflow: hidden;
     }
     .Connect_body7_message_row1_name input{
        background-color: #1b2442;
        color: #ffffff;
        min-height: 30px;
        border: none;
     }
     .Connect_body7_message_row1_email{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 10px;
        border: none;
    }
    .Connect_body7_message_row1_email input{
       background-color: #1b2442;
       color: #ffffff;
       min-height: 30px;
       border: none;
    }
    .Connect_body7_message_row2_subject{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 10px;
        border: none;
    }
    .Connect_body7_message_row2_subject input{
       background-color: #1b2442;
       color: #ffffff;
       min-height: 30px;
       border: none;
    }
    .Connect_body7_message_row3_message{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 10px;
        border: none;
    }
    .Connect_body7_message_row3_message textarea{
       background-color: #1b2442;
       color: #ffffff;
       min-height: 140px;
       border: none;
    }
    
    .Connect_body7_message_row4_send{
        
        display: flex;
    }
    .Connect_body7_message_row4_send input{
        font-size: 17px;
        font-family: "Cairo","Lustria","Poppins";
        font-weight: 500;
        background-color: #c49a6c;
        color: #1b2442;
        padding: 10px 10px;
        border: none;
    
    }
    
    
    
    
    .Connect_image2{
        grid-column: 2;
        grid-row: 1;
        display: flex;
        min-width: max-content;
    }
    .Connect_image2 img{
        width: 100%;
      height: 100%;
      object-fit: cover;
    }
}