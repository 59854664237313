.Home_main{
    display: grid;
    
    height: calc(100vh - 70px);
    /* max-width: 100vw; */
    min-width:100vw;
    max-width: 100vw;
    /* grid-template-columns: repeat(4,1fr); */
    grid-template-columns:1fr 1.2fr 1fr;
    grid-template-rows: 100%;
    grid-gap: 1em;
    margin-right: 1em;
    overflow: hidden;
    
}
.Home_image1{
    grid-column: 1;
    grid-row: 1;
    /* display: flex; */
    max-height: 100%;
    /* min-width: 100%; */
}
.Home_image1 img{
    width: 100%;
    height: 95vh;
    object-fit: cover;
}
.Home_image2{
    grid-column: 2;
    grid-row: 1;
    /* display: flex; */
    max-height: 100%;
}
.Home_image2 img{
    width: 100%;
  height: 95vh;
  object-fit: cover;
}
.Home_image3{
    grid-column: 3;
    grid-row: 1;
    display: flex;
    /* min-width: 100%; */
    max-height: 100%;
}
.Home_image3 img{
    width: 100%;
    height: 95vh;
  object-fit: cover;
}
.Home_Click_Button{
    position: absolute;
    bottom: calc(100vh - 95vh);
    /* width: 100%; */
    /* right: -50%; */
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    color: #FFFFFF;
    
    font-family:"Cairo","Poppins", sans-serif;
}
.Home_Click_Button h2{
    font-size: 16px;
    text-transform: none;
}

@media (max-width: 1200px){
    .Home_main{
        display: grid;
        height: calc(100vh - 70px);
        /* max-width: 100vw; */
        /* min-width:100vw; */
        width: 10vw;
        /* grid-template-columns: repeat(4,1fr); */
        grid-template-columns:100vw;
        grid-template-rows: 100%;
        grid-gap: 0;
        margin-right: 0;
        overflow: hidden;
        overflow-y: auto;
        
    }
    
    .Home_image2{
        grid-column: 1;
        grid-row: 1;
        display: flex;
        min-width: 100vw;
    }
    .Home_image2 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .Home_Click_Button{
        position: absolute;
        bottom: calc(100vh - 97vh);
        /* width: 100%; */
        /* right: -50%; */
        
        left: 50%;
        text-decoration: none;
        color: #FFFFFF;
        transform: translate(-50%, -50%);
        font-family:"Cairo","Poppins", sans-serif;
    }
    .Home_Click_Button h2{
        font-size: 16px;
        text-transform: none;
        
    }
}