@media(max-width:1200px){
    .Gallary_main{
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      text-transform: none;
      
    }

    /* exceptions  */
  
    .Gallery_image23{
        display: flex;
        min-width: min-content;
        /* max-height: 50vh; */
        min-height: auto;
        margin-bottom: 0;
        margin-bottom: 10px;
      }
      .Gallery_image23 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
      .Gallery_image21{
        
        display: flex;
        min-width: min-content;
        min-height: auto;
        
        margin-bottom: 10px;
      }
      .Gallery_image21 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
      .Gallery_image22_text{
       
        display: flex;
        /* min-height: calc(100% - 60px); */
        flex-wrap: wrap;
        text-align: left;
        align-items: top;
        padding: 0px 0px 0px 2px;
        background-color: #D8C9BE;
        min-width:100vw;
        min-height: auto;
        margin-bottom: 10px;
  
      }
    /* Correction */
    .Gallery_image30{
  
      display: flex;
      /* min-width: min-content; */
      width: 100vw;
      min-height: auto;
      /* max-height: 100vh; */
      margin-bottom: 10px;
    }
  
      .Gallery_image31{
        
        display: flex;
        min-width: min-content;
        max-height: 100vh;
        margin-bottom: 0;
        min-height: auto;
        margin-bottom: 10px;
      }
      .Gallery_image31 img{
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
        pointer-events: none;
      }
      .Gallery_image32{
        
        display: flex;
        min-width: min-content;
        min-height: auto;
        margin-bottom: 0;
        margin-bottom: 10px;
      }
      .Gallery_image32 img{
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
        pointer-events: none;
      }
      .Gallery_image33_text{
        
        display: flex;
        /* min-height: calc(100% - 60px); */
        flex-wrap: wrap;
        text-align: left;
        align-items: top;
        
        background-color:#CCCCCC;
        
        min-height: auto;
        padding: 0px 0px 0px 2px;
  
        min-width:100vw;
      }
    /* Correction */
      .Gallery_image43{
        grid-column: 1;
        grid-row: 2;
        display: flex;
        min-width: min-content;
        /* max-height: 50vh; */
        min-height: auto;
        margin-bottom: 0;
        margin-bottom: 10px;
      }
    
      .Gallery_image42{
        grid-column: 1;
        grid-row: 1;
        display: flex;
        min-width: min-content;
        /* max-height: 50vh; */
        min-height: auto;
        margin-bottom: 0;
        margin-bottom: 10px;
      }
      .Gallery_image42 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
      .Gallery_image41_text{
        width: 0%;
        height: 0%;
      }
      .Gallery_tile41{
      width: 0%;
      height: 0%;
      }
    /* correction */
    .Gallery_image52_text{
      grid-column: 1;
        grid-row: 2;
        display: flex;
        /* min-height: calc(100% - 60px); */
        flex-wrap: wrap;
        text-align: left;
        align-items: top;
  
        background-color: #BBB5B5;
  
        padding: 0px 0px 0px 2px;
        background-color: #D8C9BE;
        min-width:100vw;
        min-height: auto;
        margin-bottom: 10px;
    }
    .Gallery_image53{
      max-width: 0;
      height: 0%;
    }
    .Gallery_image53 img{
      max-width: 0;
      height: 0%;
      pointer-events: none;
    }
    /* Correction */
    .Gallery_image60{
      max-width: 0;
    }
    .Gallery_image60 img{
      max-width: 0;
      width: 0%;
      height: 0vh;
      pointer-events: none;
    }
    .Gallery_image61{
      grid-column: 1;
      grid-row: 1 ;
      display: flex;
      min-width: min-content;
      /* max-height: 100vh; */
      min-height: auto;
      margin-bottom: 0;
      /* margin-bottom: 10px; */
    }
    .Gallery_image61_text{
    width: 0%;
    height: 0%;
    }
    .Gallery_tile61{
    width: 0%;
    height: 0%;
    }
    .Gallery_image63_text{
      grid-column: 1;
      grid-row: 1 ;
      display: flex;
      padding: 0px 0px 0px 2px;
      background-color: #D8C9BE;
      min-width:100vw;
      /* max-height: 100vh; */
      min-height: auto;
      margin-bottom: 0;
      margin-bottom: 10px;
    }
    .Gallery_image63_text img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
    /* Correction */
    .Gallery_image73{
      width: 0%;
      height: 0%;
    }
    .Gallery_image73 img{
      width: 0%;
      height: 0%;
      pointer-events: none;
    }
    
    .Gallery_image72{
      grid-column: 1 ;
      grid-row: 1 ;
      display: flex;
      min-width: min-content;
      /* max-height: 100vh; */
      margin-bottom: 0;
      min-height: auto;
      margin-bottom: 10px;
    }
    .Gallery_image72 img{
      width: 100%;
      height: 100%;
      pointer-events: none;
      object-fit: cover;
    }
    .Gallery_image71_text{
      grid-column: 3;
        grid-row: 1;
        display: flex;
        min-height: calc(100% - 60px);
        flex-wrap: wrap;
        text-align: left;
        align-items: top;
        min-width:min-content;
        padding: 0px 0px 0px 2px;
        min-width:100vw;
        min-height: auto;
        margin-bottom: 10px;
    }
    .Gallery_tile71{
      max-height: 100%;;
      font-family: "Lustria",Sans-serif;
      font-size: 24px;
      font-weight:500;
      color: #0C142D;
      
      line-height: 1.4074;
      text-align: left;
      min-height: auto;
      margin-bottom: 10px;
    }
    .Gallery_image11_text{
      background-color:  #9BC5C3;
      padding: 0px 0px 0px 2px;
      
      min-height: auto;
    }
    /* Indian road */

    .Gallery_image81,.Gallery_image83,.Gallery_image91,
    .Gallery_image133{
        display: flex;
        min-width: 100%;
        max-height: 100vh;
        min-height: auto;
        margin-bottom: 10px;
    
    }
    .Gallery_image143 img,.Gallery_image141,.Gallery_image151 img,.Gallery_image150 img{
        width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      }

      .Gallery_image143,.Gallery_image141,.Gallery_image150,
      .Gallery_image151{
        display: flex;
        min-width: 100%;
        max-height: 100vh;
        min-height: auto;
        margin-bottom: 10px;
      }
    /* .Gallery_image92{
        max-height: 0px;
        max-width: 0px;
        
    }
    .Gallery_image92 img{
        height: 0%;
        width: 0%;
    } */
    /* Correction */
    
   /* .Gallery_video14, .Gallery_video44, .Gallery_video64{
      display: grid;
        grid-template-columns: 1fr;
        
        display: flex;
        min-width:100%;
        margin-right: 1em;
        max-width: 100vw;
        min-height: auto;
        margin-bottom: 10px;
    }
    */
  
    .Gallery_video14,.Gallery_video44, .Gallery_video64{
    
      /* display: flex; */
      margin-right: 1em;
      /* min-width: min-content; */
      /* min-height: calc(100% - 70px); */
      height: calc(9/16 * 100vw);
      width: 100vw;
    }
    .video-container {
      position: relative;
      
      padding-top: 65.25%;
      
      height: 0;
      overflow: hidden;
    }
    .video-container iframe {
      position: absolute;
      top: -30%;
      /* bottom: 10%; */
      left: 0;
      width: 135%;
      height: 135%;
    }

    .Gallery_video90,.Gallery_video113, .Gallery_video140{
      display: flex;
      margin-right: 1em;
      /* min-width: min-content; */
      /* min-height: calc(100% - 70px); */
      height: calc(16/9 * 90vw);
      width: 100vw;
      max-width: 110vw;
      margin-bottom: 1em;
    }
      /*end of corrections  */
      .video-container1 {
        position: relative;
        
        /* padding-top: 65.25%;
        
        height: 0;
        */
        overflow: hidden;
      }  
      .video-container1 iframe {
        position: absolute;
        top: -0%;
        /* bottom: 10%; */
        left: 0;
        width: 100%;
        height: 115%;
      }
      
      
      
    
      .Gallery_images3 {
        grid-area: ThirdImage;
        display: flex;
        min-width: min-content;
        max-height: 100%;
        
      }
      .Gallery_images3 img{
      
        width: 100%;
      height: 100%;
      object-fit: cover;
        
        
      }
      .Gallery_image3_text{
        grid-area: ThirdImage;
        display: flex;
        min-height: calc(100% - 60px);
        flex-wrap: wrap;
        text-align: left;
        align-items: top;
        padding: 40px 40px 0px 40px;
        background-color: #D8C9BE;
        min-width:max-content;
      }
      .Gallery_tile2{
        
        max-height: 100%;;
        font-family: "Lustria", Sans-serif;
        font-size: 24px;
        font-weight: 500;
        text-transform:none;
    
        color: #0C142D;
        
        line-height: 1.4074;
        text-align: left;
      }
      .Gallery_images4{
        grid-area: FourthImage;
        display: flex;
        flex-wrap: wrap;
        min-width:max-content;
        max-height: 100%;
        
      }
      .Gallery_images4 img{
      
        width: 100%;
      height: 100%;
      object-fit: cover;
        
        
      }
      .Gallery_image4_text{
        grid-area: FourthImage;
        display: flex;
        min-height: calc(100% - 60px);
        flex-wrap: wrap;
        text-align: left;
        align-items: top;
        padding: 40px 40px 0px 40px;
        background-color: #D8C9BE;
        min-width:max-content;
      }
      .Gallery_tile3{
        
        max-height: 100%;;
        font-family: "Lustria", Sans-serif;
        font-size: 24px;
        font-weight: 500;
        color: #0C142D;
        
        line-height: 1.4074;
        text-align: left;
      }
      .Gallery_Containers{
        display: flex;
        overflow: hidden;
        /* min-width: 100vw; */
        max-height: calc(100vh - 70px);
    
       
      }
}