
  .Gallary_main{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    max-height: 100vh;
    max-width: 100vw;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .Gallery_Container{
    display: flex;
    max-width: none;
    max-height: calc(100vh - 70px);
    text-transform: none;
   
   
  }

  .Gallery_Grid{
    display: grid;
    height: calc(100vh - 70px);
    /* max-width: 350vw; */
    /* max-width: 100vw; */
    min-width:min-content;
    /* grid-template-columns: repeat(4,1fr); */
    grid-template-columns:auto auto auto auto;
    grid-template-rows: 40vh 50vh;
    grid-gap: 1em;
    
    
  }
  
  
  .Gallery_image00, .Gallery_image10, .Gallery_image20,
  .Gallery_image40,.Gallery_image50, .Gallery_image60, .Gallery_image70,
  .Gallery_image80,.Gallery_image90, .Gallery_image100, .Gallery_image110, 
  .Gallery_image120,.Gallery_image130, .Gallery_image140{
    grid-column: 1;
    grid-row: 1/span 2;
    /* max-width: 50vw; */
    display: flex;
    /* min-width: max-content; */
    align-self: flex-start;
    max-height: 100%;
  }
  .Gallery_image00 img, .Gallery_image10 img, .Gallery_image20 img,
  .Gallery_image30 img,.Gallery_image40 img,.Gallery_image50 img,
   .Gallery_image60 img, .Gallery_image70 img,
   .Gallery_image80 img,.Gallery_image90 img, .Gallery_image100 img, .Gallery_image110 img, 
   .Gallery_image120 img,.Gallery_image130 img, .Gallery_image140 img{
  /* width: 100%; */
  height: 95vh;
  object-fit: cover;
  }

  .Gallery_image01, .Gallery_image11,.Gallery_image31
  ,.Gallery_image41,.Gallery_image51,  .Gallery_image71,
   .Gallery_image101,
  .Gallery_image131{
    grid-column: 2/span 2;
    grid-row: 1;
    display: flex;
    /* min-width: max-content; */
    /* min-width: 100%; */
    max-height: 50vh;
    margin-bottom: 0;
  }
  .Gallery_image01 img, .Gallery_image11 img,
  .Gallery_image31 img,.Gallery_image41 img,.Gallery_image51 img,
    .Gallery_image71 img, .Gallery_image101 img,
   .Gallery_image131 img{
    width: 100%;
  height: 40vh;
  object-fit: cover;
  }

  .Gallery_image02, .Gallery_image12, .Gallery_image22,.Gallery_image32
  ,.Gallery_image42,.Gallery_image52, .Gallery_image72,.Gallery_image82, .Gallery_image112  {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    margin-bottom: 0;
  }
  .Gallery_image02 img, .Gallery_image12 img, .Gallery_image22 img,
  .Gallery_image32 img,.Gallery_image42 img,.Gallery_image52 img,
   .Gallery_image72 img, .Gallery_image82 img, .Gallery_image112 img{
    /* width: 100%; */
  height: 55vh;
  object-fit: cover;
  }

  .Gallery_image03, .Gallery_image13, .Gallery_image23
  ,.Gallery_image43, .Gallery_image63, .Gallery_image83,.Gallery_image93, .Gallery_image113,
  .Gallery_image123, .Gallery_image143  {
    grid-column: 3;
    grid-row: 2;
    display: flex;
    /* min-width: max-content; */
    max-height: 50vh;
    margin-bottom: 0;
  }
  .Gallery_image03 img, .Gallery_image13 img,
  .Gallery_image33 img,.Gallery_image43 img,
   .Gallery_image63 img, .Gallery_image83 img,.Gallery_image93 img, .Gallery_image113 img,
   .Gallery_image123 img, .Gallery_image143 img{
    /* width: 100%; */
  height: 55vh;
  object-fit: cover;
  }

  .Gallery_image03_text,.Gallery_image13_text, .Gallery_image23_text,
  .Gallery_image33_text, .Gallery_image43_text, .Gallery_image53_text,
  .Gallery_image73_text{
    grid-column: 3;
    grid-row: 2;
    display: flex;
    /* min-height: calc(100% - 60px); */
    height: 50vh;
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
    min-width:max-content;
  }
  .Gallery_tile03, .Gallery_tile13, .Gallery_tile23, .Gallery_tile33,
  .Gallery_tile43, .Gallery_tile53,  .Gallery_tile73, .Gallery_tile93{
    
    max-height: 55vh;
    font-family: "Cairo","Poppins", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #0C142D;
    
    line-height: 1.4074;
    text-align: left;
  }

  .Gallery_image02_text,.Gallery_image12_text, 
  .Gallery_image32_text, .Gallery_image42_text, .Gallery_image52_text,
  .Gallery_image62_text, .Gallery_image72_text{
    grid-column: 2;
    grid-row: 2;
    display: flex;
    /* min-height: calc(100% - 60px); */
    height: 50vh;
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
    min-width:max-content;
  }
  .Gallery_tile02, .Gallery_tile12, .Gallery_tile22, .Gallery_tile32,
   .Gallery_tile52, .Gallery_tile62, .Gallery_tile72,.Gallery_tile112{
    
    max-height: 55vh;
    font-family: "Cairo","Poppins", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #0C142D;
    
    line-height: 1.4074;
    text-align: left;
  }

  .Gallery_image01_text,.Gallery_image11_text, .Gallery_image21_text,
  .Gallery_image31_text, .Gallery_image51_text,
  .Gallery_image61_text{
    grid-column: 2 /span 2;
    grid-row: 1;
    display: flex;
    /* min-height: calc(100% - 60px); */
    /* height: 40vh; */
    flex-wrap: wrap;
    text-align: left;
    align-items: top;
    padding: 40px 40px 0px 40px;
    background-color: #D8C9BE;
    /* min-width:max-content; */
  }
  .Gallery_tile01, .Gallery_tile11, .Gallery_tile21, .Gallery_tile31,
  .Gallery_tile41, .Gallery_tile51, .Gallery_tile61, .Gallery_tile101{
    
    max-height: 40vh;
    font-family: "Cairo","Poppins", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #0C142D;
    
    line-height: 1.4074;
    text-align: left;   
    display: flex;
    flex-wrap: wrap;
  }
  .Gallery_Containers{
    /* display: flex; */
    /* max-width: max-content;     */
    min-width: fit-content;
    max-width: 100vw;
    max-height: calc(100vh - 70px);

  }
  .Gallery_image04, .Gallery_image14, .Gallery_image24,.Gallery_image34
  ,.Gallery_image44,.Gallery_image54, .Gallery_image64, .Gallery_image74, .Gallery_image84, .Gallery_image114,
  .Gallery_image124,.Gallery_image134, .Gallery_image144 {
    /* display: grid;
    grid-template-columns: 1fr;
    min-width: 100vw; */
    /* display: flex; */
    grid-column: 4;
    grid-row: 1 /span 2;
    min-width:max-content;
    /* margin-right: 1em; */
    /* max-width: 100vw; */
    /* min-height: calc(100% - 70px); */
    max-height: 100vh;
    margin-right:1em ;
    
  }
  .Gallery_image04 img, .Gallery_image14 img, .Gallery_image24 img,
  .Gallery_image34 img, .Gallery_image44 img,.Gallery_image54 img,
  .Gallery_image64 img, .Gallery_image74 img, .Gallery_image84 img,
  .Gallery_image94 img, .Gallery_image114 img,
  .Gallery_image124 img,.Gallery_image134 img, .Gallery_image144 img{
    /* width: 100%; */
    height: 95vh;
    object-fit: cover;
  }

  

  .Gallery_ArrowRight{
    position: fixed;
    top: 50%;
    right: -30px;
    width: 50px;
    transition: 0.3s;
    padding: 10px;
    text-decoration: none;
    font-size: 16px;
    color: #0622e6;
    z-index: 9999;
    background-color: rgb(214 232 247 / 0%);
    border-radius: 25px 0px 0px 25px;
    cursor: pointer;
    opacity: 1;
    
  }
  .Gallery_ArrowRight img{
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    
  }
  .Gallery_ArrowLeft{
    position: fixed;
    top: 50%;
    left: -30px;
    width: 50px;
    transition: 0.3s;
    padding: 10px;
    text-decoration: none;
    font-size: 16px;
    color: #0622e6;
    z-index: 9999;
    background-color: rgb(214 232 247 / 0%);
    border-radius: 0px 25px 25px 0px;
    cursor: pointer;
    opacity: 1;
    
  }
  
  
  .Gallery_ArrowLeft img{
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    
  }









